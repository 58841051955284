<template>
    <div class="out">
        <div class="top_logo">
            <img src="../assets/img/logo-icon.png" alt="">
        </div>
        <div class="title_logo">
            <img src="../assets/img/bigtitle.png" alt="" srcset="">
        </div>
        <div class="czz_bu" @click="gotoHome('/home')">
            {{$t('main.iata')}}
        </div>
        <div class="scj_bu" @click="gotoHome('/home_col')">
            {{$t('main.iatc')}}
        </div>
    </div>
</template>

<script>
export default {
    methods:{
        gotoHome(path){
            this.$router.push(path)
        }
    }
}
</script>

<style scoped>
.out{
    min-height: 100vh;
	box-sizing: border-box;
	background: url(../assets/img/home_ho_bg.png) no-repeat center;
	background-size: cover;
	position: relative;
    padding-top: 0.3rem;
    /* filter:grayscale(100%); */
}
.top_logo{
    margin: 0 auto;
    width: 1.26rem;
}
.top_logo img{
   
    width: 1.26rem;
    height: 0.48rem;
    object-fit: contain;
}
.title_logo{
    margin: 0 auto;
    width: 3.48rem;
}
.title_logo img{
   width: 3.48rem;
   height: 0.67rem;
   object-fit: contain;
}
.czz_bu{
    margin: 0 auto;
    margin-top:0.4rem;
    font-size: 0.15rem;
    color: #fff;
    width: 3.04rem;
    height: 1.13rem;
    box-sizing: border-box;
    padding-left: 0.6rem;
    padding-bottom: 0.05rem;
    display: flex;
    align-items: center;
    background: url(../assets/img/czz_bu.png) no-repeat;
    background-size: 100% 100%;
	font-weight: bold;
}
.scj_bu{
    margin: 0 auto;
    font-size: 0.15rem;
    color: #fff;
    width: 3.04rem;
    height: 1.13rem;
    box-sizing: border-box;
    padding-left: 0.6rem;
    padding-bottom: 0.05rem;
    display: flex;
    align-items: center;
    background: url(../assets/img/scj_bu.png) no-repeat;
    background-size: 100% 100%;
	font-weight: bold;
}
</style>